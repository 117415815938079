.img-box{
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  margin-left:5px;
  margin-right:5px;
}

.img-box-preview-show{
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  margin-left:30px;
  margin-right:30px;
}
.img-box-preview-hide{
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  margin-left:30px;
  margin-right:30px;
}
.img-file{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    z-index: 9999;
    margin-left: -18px;
    margin-top: -41px;
    opacity: 0;
    filter:Alpha(opacity=0);
}
.img-add {
    width: 130px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -32px;
    left: 50%;
    margin-left: -65px;
}
.img-add-icon{
  font-size: 30px;
  font-weight: bold;
}
.img-preview{
  display: none;
  position: absolute;
  top:50%;
  left:50%;
  margin-top:-22px;
  margin-left:-44px;
}
.img-box-preview-show:hover .img-preview{
   display:block;
}
.img-operate{
  font-size:24px;
  margin:10px;
  color: red;
}
.img-loading{
  position: absolute;
  top:50%;
  left:50%;
  margin-top:-50px;
  margin-left:-50px;
}
.preview-all {
  width:100%;
}
.img-center{
  text-align: center;
}
.img-wh{
  width:100%;
  height:100%;
}