.list-sort-demo-wrapper {
    position: relative;
    /*background: #e6e6e6;*/
    overflow: hidden;
    /*height: 385px;*/
}

.list-sort-demo {
    margin: 0px auto;
    max-width: 350px;
    width: 100%;
    cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important;
    position: relative;
    /*height: 305px;*/
}
.list-sort-demo > div{
    overflow: hidden;
}

.list-sort-demo-list {
    background: #fff;
    border-radius: 0px;
    margin: 5px auto;
    padding: 0px;
    /*height: 70px;*/
    transition:  box-shadow .5s, transform .5s;
}

.list-sort-demo-list.list-drag-selected{
    box-shadow: 0 0px 0px #E6E6E6;
    transform: scale(1) !important;
}

.list-sort-demo-icon {
    width: 20%;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    vertical-align: top;
}
.list-sort-demo-text{
    width: 100%;
    display: inline-block;
}

.list-sort-demo-text h1 {
    font-size: 18px;
}

.list-sort-demo-text p{
    font-size: 12px;
}

@media screen and (max-width: 320px) {
    .list-sort-demo-text h1 {
        font-size: 14px;
        line-height: 28px;
    }
}

.card-wrapper {
    position: relative;
    /*background: #e6e6e6;*/
    overflow: hidden;
    /*height: 385px;*/
}

.card-list {
    margin: 0px auto;
    /*max-width: 350px;*/
    width: 100%;
    cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10,pointer!important;
    position: relative;
    /*height: 305px;*/
}

.card-list > div{
    overflow: hidden;
    /*display: inline-block;*/
    width: 100%;
}

.card {
    /*width: 20%;*/
    width: 100%;
    float:left;
    /*display: inline-block;*/
    background: #fff;
    border-radius: 0px;
    margin: 5px 10px auto;
    padding: 0px;
    /*height: 70px;*/
    transition:  box-shadow .5s, transform .5s;
}

.card.list-drag-selected{
    box-shadow: 0 0px 0px #E6E6E6;
    transform: scale(1) !important;
}
